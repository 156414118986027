import React from "react"
import PropTypes from "prop-types"

import "./layout.sass"
import Header from "../header/header"
import Footer from "../footer/footer"
import AdBanner from "../adbanner/adbanner"

const Layout = ({ children }) => {

  return (
    <div className="layout">
      <Header></Header>
      <AdBanner></AdBanner>
      {children}
      <Footer></Footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
