import React from "react"
import "./footer.sass"
import flowerImg from "../../../static/images/flower-pink.png" 

const Footer = () => (
    <div className="footer">
      <img className="footer__flower" alt="elemento decorativo del pie de página" src={flowerImg}></img>
      <div className="footer__content">
        <div className="footer__info-wrap">
          <div className="footer_info-title-wrap">
            <i className="material-icons">phone</i><p className="footer__info-title">Teléfono</p>
          </div>
          <p className="footer__info">314 4101492 <br></br> 320 3298045</p>
          <p className="footer__info">2152300 <b>Ext.</b>1320</p>
        </div>
        <div className="footer__info-wrap">
          <div className="footer_info-title-wrap">
            <i className="material-icons">home</i><p className="footer__info-title">Dirección</p>
          </div>
          <p className="footer__info">Av 9 #116-20 Cons. 320</p>
          <p className="footer__info"><b>Bogotá - Colombia</b></p>
        </div>
        <div className="footer__info-wrap">
          <div className="footer_info-title-wrap">
          <i className="material-icons">email</i><p className="footer__info-title">Email</p>
          </div>
          <p className="footer__info">fpgm14@yahoo.es</p>
        </div>
      </div>
      <p className="footer__copyright"> Creado por MedSys 2006-2020 © Todos los derechos reservados. </p>
    </div>
)
export default Footer
