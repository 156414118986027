import React from "react"
import "./adbanner.sass"
import { Link } from "gatsby"
import Whatsapp from "../../../static/images/whatsapp.png"
import Insta from "../../../static/images/insta.png"

const AdBanner = () => (
    <div className="header__link">
      <div className="adbanner__insta">
        <a href="https://www.instagram.com/dra.patriciagomez/" className="adbanner__insta-content-wrap">
          <img className="adbanner__insta-img" src={Insta} alt="Instagram Logo" />
            <p className="adbanner__insta-description">Sígueme en Instagram</p>
        </a>
      </div>
    <div className="adbanner">
      <p className="adbanner__title">¡Ahora realizamos <br/> <b>TELECONSULTAS</b>!</p>
      <p className="adbanner__description">Comunícate con nosotros para conocer más</p>
      <p className="adbanner__description">Nos ubicamos en <b>Bogotá</b></p>
      <a className="adbanner__btn" href="https://wa.link/r1lgav">
        <img src={Whatsapp} className="adbanner__icon"/>
        Escríbenos en Whatsapp
      </a>
    </div>
  </div>
)
export default AdBanner
