import React from "react"
import { Link } from "gatsby"
import "./header.sass"
import flowerImg from "../../../static/images/flower.png"

class Header extends React.Component{

  state = {
    isMenuOpen: false
  }

  switchMenu(){
    if(window.innerWidth<667)
     this.setState({isMenuOpen:!this.state.isMenuOpen})
  }

  handleResize = e => {
    const isDesktop = window.innerWidth>=667;
    if(isDesktop)
      this.setState({isMenuOpen: true})
    else
      this.setState({isMenuOpen: false})
  };


  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const isDesktop = window.innerWidth>=667;
    if(isDesktop)
      this.setState({isMenuOpen: true})
    else
      this.setState({isMenuOpen: false})
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render (){
    return (
      <div className="header">
        <div className="header__content">
          <div className="header__phone-number-wrap">
            <p className="header__phone-number"><i className="material-icons header__phone-icon">smartphone</i>3144101492 - 3203298045</p>
            <p className="header__city">
              <i className="material-icons header__phone-icon">location_on</i> Estamos en &thinsp;<b>Bogotá</b></p>
          </div>
        <div className="header__title-wrap">
            <img className="header__title-flower" alt="elemento decorativo del titulo" src={flowerImg}></img>
            <Link className="header__link" to="/">
              <h1 className="header__title">Dra. Patricia Gómez</h1>
            </Link>
          </div>
          <div className="header__subtitle-wrap">
            <h2 className="header__subtitle">Ginecóloga</h2>
          </div>
          <h3 className="header__tagline"> La salud en la mujer es una prioridad,
              la medicina privada está al alcance de tus manos.</h3>
        </div>
        <div className="header__divider"></div>
        <div className="header__mobile-menu">
          <div tabIndex={0} role = "button" onKeyDown={()=>this.switchMenu()} onClick={()=>this.switchMenu()}>
            <span className="header__menu-icon material-icons"> menu </span>
          </div>
        </div>
        <div className={this.state.isMenuOpen?"header__navigation":"header__display-none"}>
          <div className="header__close-wrap">
            <div tabIndex={0} role = "button" onKeyDown={()=>this.switchMenu()} onClick={()=>this.switchMenu()}
              className="header__close">
                <i className="material-icons">close</i>
            </div>
          </div>
          <Link onClick={()=>this.switchMenu()} className="header__link" to="/">
            <div className="header__link-wrap">
                Ginecóloga
            </div>
          </Link>
          <Link onClick={()=>this.switchMenu()} className="header__link" to="/servicio/">
            <div className="header__link-wrap">
              Servicios
              </div>
          </Link>
          <Link onClick={()=>this.switchMenu()} className="header__link" to="/nuestrosproductos/">
          <div className="header__link-wrap">
            <p className="header__link-content">
              Nuestros productos<br></br>
              <span className="header__small-txt">Para la salud femenina tenemos:...</span>
            </p>
          </div>
          </Link>
          <Link onClick={()=>this.switchMenu()} className="header__link" to="/apartarcita/">
          <div className="header__link-wrap">
            Aparta tu cita
          </div>
          </Link>
          <Link onClick={()=>this.switchMenu()} className="header__link" to="/contacto/">
          <div className="header__link-wrap">
            Contacto
          </div>
          </Link>
        </div>
      </div>
    )
  }
}

export default Header
